import React from "react"
import Fade from "react-reveal/Fade"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Privacy = () => (
  <Layout>
    <SEO title="Aviso de Privacidad" />
    <div className="hero is-medium">
      <div className="hero-body mainHeroCover">
        <div className="container has-text-centered">
          <div className="columns ">
            <div className="terms-header">
              <Fade delay={200}>
                <h1 className="title land-about__title covid-title">
                  Términos y Condiciones
                </h1>
                <p>
                  <strong>Aviso de Privacidad</strong>
                </p>
                <p>
                  Develoft S.A. de C.V. (la “Empresa”), adquiere el compromiso
                  de resguardar y proteger su privacidad. La presente
                  declaración de privacidad explica e informa acerca de
                  recopilación, así como el uso de su información personal a
                  través del sitio superparo.com (el “Sitio”). Al ingresar al
                  Sitio, usted manifiesta su consentimiento en las formas de
                  recopilación y uso de información contenida en la presente
                  declaración de privacidad.
                </p>
                <p>
                  <strong>Recopilación y uso de información personal</strong>
                </p>
                <p>
                  'Se le solicitará información personal o que nos permita,
                  cuando sea necesario, ponernos en contacto con usted, con el
                  objetivo de proporcionar un servicio y/o realizar alguna
                  transacción que nos haya solicitado.', La información
                  recopilada permite analizar tendencias, además de ayudarnos a
                  mejorar y administrar el Sitio.',
                </p>
                <p>
                  'La recopilación de información personal podrá incluir su
                  nombre, correo electrónico, tratamiento, nombre de la empresa
                  u organización, teléfono, dirección de trabajo y/o particular,
                  información sobre sus funciones en el trabajo, información
                  sobre de su empresa en su caso.',
                </p>
                <p>
                  'El Sitio está facultado para recopilar información que tenga
                  y sea relevante a su visita, como el nombre del proveedor de
                  servicios Internet y la dirección IP (Protocolo Internet)
                  mediante la cual tiene acceso a Internet; la fecha y la hora
                  de acceso al Sitio; las páginas a las que ha tenido acceso en
                  el Sitio y la dirección de Internet del sitio Web desde el que
                  ha llegado a nuestro Sitio.
                </p>
                <p>
                  'La información personal recopilada en este Sitio servirá para
                  el funcionamiento del Sitio y para proporcionar los servicios
                  o realizar las transacciones que se hayan solicitado o
                  autorizado.',
                </p>
                <p>
                  'Para la finalidad antes mencionada la Empresa puede utilizar
                  su información personal para poder ofrecerle un servicio más
                  efectivo, así como para mejorar el Sitio y cualquier producto
                  y servicio relacionado de la Empresa, además facilitar el uso
                  de el Sitio, evitando que de manera repetitiva se tenga que
                  especificar la información o bien personalizando el Sitio
                  conforme a sus intereses y preferencias personales',
                </p>
                <p>
                  ' La información personal puede ser utilizada para que, con su
                  consentimiento, podamos ofrecerle y hacerle llegar información
                  acerca de otros productos y servicios de la Empresa o bien
                  para compartir la información con empresas y personas
                  asociados de la Empresa para que puedan ofrecerle información
                  sobre sus productos y servicios.',
                </p>
                <p>
                  'Su información personal puede ser divulgada por la Empresa en
                  caso de que así lo exija la ley o si de buena fe se cree que
                  es necesario para cumplir la ley o procesos legales que estén
                  relacionados con la Empresa o el Sitio, o con el fin
                  salvaguardar y proteger los derechos de propiedad de la
                  Empresa o para proteger en casos urgentes la seguridad
                  personal de los empleados de la Empresa, los usuarios de los
                  productos o servicios de la Empresa o en general de miembros
                  del público.
                </p>
                <p>
                  <strong>
                    Control y seguridad de la información personal
                  </strong>
                </p>
                <p>
                  'Control y seguridad de la información personal Sin su permiso
                  la información personal que proporcione en el Sitio no será
                  compartida fuera de la Empresa, ni de sus afiliadas y
                  subsidiarias controladas, excepto cuando se le indique lo
                  contrario y usted otorgue su consentimiento. Usted puede tener
                  acceso a su información personal, así como elegir la manera en
                  la que desea que se pongan en contacto con usted.',
                </p>
                <p>
                  'Esta declaración de privacidad y la elección de las
                  aplicaciones que realice en el Sitio no necesariamente serán
                  aplicables a la información personal proporcionada a la
                  Empresa en el contexto de otros productos o servicios
                  independientes de la Empresa.',
                </p>
                <p>
                  'El Sitio, está facultado para enviar de forma periódica
                  correos electrónicos informando sobre temas de seguridad o de
                  servicio técnico de un producto o servicio solicitado, así
                  como de confirmación de un producto o servicio solicitado. En
                  ciertos servicios que ofrece el Sitio, el contactar al cliente
                  es fundamental, por lo cual no se podrá cancelar la
                  suscripción a estos mensajes.',
                </p>
                <p>
                  'La Empresa se compromete a proteger la seguridad de la
                  información personal, utilizando tecnologías y procedimientos
                  de seguridad para proteger que se acceda, use o divulgue su
                  información personal sin autorización.'
                </p>
                <p>
                  <strong>
                    Modificaciones a esta declaración de privacidad
                  </strong>
                </p>
                <p>
                  'Modificaciones a esta declaración de privacidad La Empresa se
                  reserva el derecho de actualizar y/o modificar esta
                  declaración de privacidad. Al momento de actualizarse, la
                  fecha de “última actualización” de la parte superior de la
                  declaración de privacidad, se cambiará.',
                </p>
                <p>
                  'En caso de realizarse modificaciones importantes en la
                  declaración de privacidad, se le comunicará por medio de un
                  aviso destacado del Sitio. Por lo que le sugerimos que revise
                  el Sitio de manera periódica, ya que una vez publicados los
                  cambios, significará que usted ha aceptado los cambios, y que
                  otorga su consentimiento a los mismos.'
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Privacy
